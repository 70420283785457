export const dataProcess = [
    {  
       accHeader: 'Adquisición de información',
       accBody: 'Adquisición de información de la empresa a través de un cuestionario con el objetivo de conocer sobre su filosofía, entorno, misión, visión, publico objetivo, productos y otros datos relevantes..',
    },
    {  
       accHeader: 'Análisis',
       accBody: 'Analisis de la información obtenida, busqueda de puntos comunes y diferenciadores con respecto a la competencia, sitentización de los valores y objetivos de la empresa.',
    },
    {  
       accHeader: 'Desarrollo de líneas de diseño',
       accBody: 'Desarrollo de diversos conceptos que simbolicen a la marca y bocetación de los mismos.',
   },
   {  
       accHeader: 'Definición',
       accBody: 'Elección de linea de diseño, ajustes y vectorización final.',
   },
   {  
       accHeader: 'Paleta de color y tipografía',
       accBody: 'Elección definiva de paleta de color para formatos digitales y físicos. Elección de la tipografía del logotipo y el eslogan.',
   },
   {  
       accHeader: 'Versiones',
       accBody: 'Creación de versiones de la marca en distintos formatos y sobre distintos fondos.',
   },
   {  
       accHeader: 'Manual de marca',
       accBody: 'Creación de manual con las versiones del logo original, positivo, negativo, continuo, horizontal, vertical, mínimo, convivencia, grilla, usos incorrectos, paleta de color, tipografía, elementos complementarios y otros.',
   },
    
 ]
    
 
 
 export const dataItem = [
    {  
       itemTitle: 'Líneas de diseño',
       itemDesc: 'En los bocetos se probaron varios conceptos partiendo por el nombre de la empresa, ChocoGrain (granos de chocolate), amor y seducción, debido a que el chocolate se utiliza a menudo como regalo entre enamorados.',
    },
    {  
       itemTitle: 'Concepto isotipo',
       itemSubtitle: '¿De dónde sale el chocolate?',
       itemDesc: 'Todos sabemos que existen muchos tipos de chocolate, de cobertura, blanco, líquido, fondant, con leche, amargo, etc. Todos estos salen de la combinación de granos de cacao.',
       itemSubtitle2: '¿Que nos hace sentir el chocolate?',
       itemDesc3: 'El chocolate mejora el humor, es un excelente y sofisticado regalo para agasajar o enamorar en grande, es un alimento fascinante que durante cientos de años nos ha acompañado en el amor.',
    },
    {  
       itemTitle: 'Color',
       itemDesc: 'Para representar la marca se escogió el fuccia por ser singular y extravagante. Su mixtura del color rojo y azul hace que se vincule con el artista porque combina conceptos ambiguos como la sensualidad y la espiritualidad, el sentimiento y el entendimiento, el amor y la abstinencia. El fuccia es símbolo de poder debido a que que su pigmento era extremadamente difícil de conseguir y usado por emperadores para ostentar su riqueza y rango social, además de simbolizar la eternidad por su estabilidad bajo los efectos de la luz. Al aplicar el color sobre el isotipo, que es una llama, le otorga originalidad debido a que no es el color naturalmente asociado al fuego.',
    },
    {  
        itemTitle: 'Tipografía',
        itemDesc: 'Chocolate Dulce es una tipografía caligrafica de trazos de grosos variable, solido y con terminaciones curvas. Sus formas curvas y organicas y trazos abiertos, hacen que combine con el isotipo. Esta fuente fue creada por Sudtipos, Paul & Koziupa.',
     },
     {  
        itemTitle: 'Imagotipo',
        itemDesc: 'En su versión vertical consta del isotipo colocado sobre el logotipo de forma centrada, esta versión destaca el isotipo. En la horizontal el isotipo se coloca a la izquierda del logotipo y permite la aplicacion del mismo en tamaños mas pequeños, donde se puede leer el nombre.',
     },
    {  
       itemTitle: 'Versiones y variantes de color',
       itemSubtitle: 'Positivo una tinta',
       itemDesc: 'Versión del logo sobre fondos claros con una sola tinta, utilización de colores corporativos o negro #000000 / Pantone Black 6 C.',
    },
    {  
       itemTitle: 'Versiones y variantes de color',
       itemSubtitle: 'Positivo varias tintas',
       itemDesc: 'Versión Versión del logo sobre fondos claros con mas de una tinta, utilización de colores corporativos o negro #000000 / Pantone Black 6 C con gris #393939 / Pantone 447 C.del logo sobre fondos claros con mas de una tinta, utilización de colores corporativos o negro #000000 / Pantone Black 6 C.',
    },
    {  
       itemTitle: 'Versiones y variantes de color',
       itemSubtitle: 'Negativo una tinta',
       itemDesc: 'Versión del logo sobre fondos oscuros con una sola tinta, utilización de colores corporativos o negro #000000 / Pantone Black 6 C, logo #ffffff / papel blanco.',
    },
    {  
        itemTitle: 'Versiones y variantes de color',
        itemSubtitle: 'Negativo varias tintas',
        itemDesc: 'Versión del logo sobre fondos oscuros con más de una tinta, utilización de colores corporativos o negro #000000 / Pantone Black 6 C con gris #b3b3b3 / Pantone Cool Gray 5 C, blanco #ffffff / papel blanco.',
    },
    {  
       quoteText: 'El buen diseño es obvio. El diseño genial es transparente.',
       quoteAutor: 'Joe Sparano',
    },
 
 ]